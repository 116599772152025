class IntersectObserver {
    entries = {};
    observer = null;
    count = 0;

    constructor() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            threshold: 0.0,
        });
    }

    createID(el) {
        if (!el.dataset.intersectId) {
            this.count += 1;
            return this.count;
        }
        return el.dataset.intersectId;
    }


    observe(id = null, el, methodIn = () => {}, methodOut = () => {}) {
        const _id = id || this.createID(el);
        el.dataset.intersectId = _id;
        this.entries[_id] = { el, methodIn, methodOut };
        this.observer.observe(el);
        return id;
    }

    unobserve(id, el) {
        this.observer.unobserve(el);
        delete this.entries[id];
    }

    onElementObserved = (entries) => {
        entries.forEach((entry) => {
            const id = entry.target.dataset.intersectId;

            if (id && this.entries[id]) {
                if (entry.isIntersecting) {
                    // debug('entry.isIntersecting', entry.target);
                    this.entries[id].methodIn(entry);
                } else {
                    this.entries[id].methodOut(entry);
                }
            }
        });
    }
}

export default new IntersectObserver();
